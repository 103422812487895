<section class="details">
  <div class="custom-container">
    <ng-content></ng-content>

    <div class="house-details">
      <div class="slider dn--print">
        <app-images-carousel-base
          *ngIf="has2dSection('images')"
          [images]="property.images"
        ></app-images-carousel-base>
      </div>

      <div class="title-container no-page-break screen:hidden mb-3">
        <h2>
          {{ property.title }}
        </h2>

        <div *ngIf="has2dSection('address')" class="zone-and-city">
          <span *ngIf="property.city && property.zone">
            Adresa:
            {{ property.website_address }}
            <ng-container *ngIf="property.sublocality">
              ,
              {{
                'model.property.sublocality.' + property.sublocality | translate
              }}
            </ng-container>
            , {{ property.city.name }}
            <ng-container *ngIf="property.city.name != property.county.name">
              , {{ property.county.name }}
            </ng-container>
          </span>
        </div>
      </div>

      <div *ngIf="has2dSection('images')" class="hidden print:block">
        <div class="grid grid-cols-2 gap-2.5">
          <a *ngFor="let image of property.images" [href]="image.url">
            <img
              class="aspect-video object-contain w-full"
              [src]="image.url"
              loading="eager"
            />
          </a>
        </div>
      </div>

      <div class="house-description">
        <div class="no-page-break">
          <div class="description-list">
            <div class="row">
              <div
                *ngIf="has2dSection('price_details')"
                class="col-xs-12 col-sm-6 separator-right"
              >
                <h5>Detalii preț</h5>
                <table>
                  <tbody>
                    <tr *ngIf="property.for_sale && property.sale_price">
                      <td>Preț vânzare:</td>
                      <td>
                        <b [class.has-promo]="property.promo_sale_price || promoPrice">
                          {{ property.sale_price | number : '.0-0' }}
                          {{
                            'model.currency_id.' + property.currency_id
                              | translate
                          }}

                          <span
                            *ngIf="
                              property.sale_vat && property.sale_vat != 'no_vat'
                            "
                            class="vat"
                          >
                            {{
                              'model.property.sale_vat.' + property.sale_vat
                                | translate
                            }}
                          </span>
                        </b>
                      </td>
                    </tr>
                    <tr *ngIf="property.promo_sale_price && !promoPrice">
                      <td>Preț promoțional:</td>
                      <td>
                        <b>
                          {{
                            property.promo_sale_price.cents / 100
                              | number : '.0-0'
                          }}
                          {{
                            'model.currency_id.' +
                              property.promo_sale_price.currency_iso | translate
                          }}

                          <span
                            *ngIf="
                              property.sale_vat && property.sale_vat != 'no_vat'
                            "
                            class="vat"
                          >
                            {{
                              'model.property.sale_vat.' + property.sale_vat
                                | translate
                            }}
                          </span>
                        </b>
                      </td>
                    </tr>
                    <tr *ngIf="promoPrice">
                      <td>Preț promoțional ofertă:</td>
                      <td>
                        <b>
                          {{
                            promoPrice.cents / 100
                              | number : '.0-0'
                          }}
                          {{
                            'model.currency_id.' +
                              promoPrice.currency_iso | translate
                          }}

                          <span
                            *ngIf="
                              property.sale_vat && property.sale_vat != 'no_vat'
                            "
                            class="vat"
                          >
                            {{
                              'model.property.sale_vat.' + property.sale_vat
                                | translate
                            }}
                          </span>
                        </b>
                      </td>
                    </tr>
                    <tr *ngIf="property.to_let && property.let_price">
                      <td>Preț închiriere:</td>
                      <td>
                        <b>
                          {{ property.let_price | number : '.0-0' }}
                          {{
                            'model.currency_id.' + property.currency_id
                              | translate
                          }}
                          pe lună

                          <span
                            *ngIf="
                              property.let_vat && property.let_vat != 'no_vat'
                            "
                            class="vat"
                          >
                            {{
                              'model.property.let_vat.' + property.let_vat
                                | translate
                            }}
                          </span>
                        </b>
                      </td>
                    </tr>
                    <tr *ngIf="property.price_type_copy">
                      <td class="price-type-copy" colspan="2">
                        <div class="whitespace-pre-wrap">{{ property.price_type_copy }}</div>
                      </td>
                    </tr>
                    <tr *ngIf="property.representation">
                      <td>Reprezentare:</td>
                      <td>
                        {{
                          'model.property.representation.' +
                            property.representation | translate
                        }}
                      </td>
                    </tr>
                    <tr *ngIf="property.commission_description">
                      <td>Comision:</td>
                      <td>
                        {{ property.commission_description }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                *ngIf="has2dSection('property_identification')"
                class="col-xs-12 col-sm-6 separator-right"
              >
                <h5>Identificare locuință</h5>
                <div class="description-list">
                  <table class="field-table">
                    <tr *ngIf="property.project != null">
                      <td>
                        {{ 'fields.property.project' | translate }}
                      </td>
                      <td>
                        {{ property.project.name }}
                      </td>
                    </tr>
                    <tr *ngIf="property.number != null">
                      <td>
                        {{ 'fields.property.number' | translate }}
                      </td>
                      <td>
                        {{ property.number }}
                      </td>
                    </tr>
                    <tr *ngIf="property.floor_value != null">
                      <td>
                        {{ 'fields.property.floor' | translate }}
                      </td>
                      <td>
                        {{ property.floor_value }}
                      </td>
                    </tr>
                    <tr *ngIf="property.type_name != null">
                      <td>
                        {{ 'fields.property.type_name' | translate }}
                      </td>
                      <td>
                        {{ property.type_name }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="no-page-break">
          <div class="description-list">
            <div class="row">
              <div
                *ngIf="has2dSection('property_details')"
                class="col-xs-12 col-sm-6 separator-right"
              >
                <h5>Detalii locuință</h5>
                <table class="field-table">
                  <tr *ngIf="property.room_count != null">
                    <td>
                      {{ 'fields.property.room_count' | translate }}
                    </td>
                    <td>
                      {{ property.room_count | number: '.0-1' }}
                    </td>
                  </tr>
                  <tr *ngIf="property.bathroom_count != null">
                    <td>
                      {{ 'fields.property.bathroom_count' | translate }}
                    </td>
                    <td>
                      {{ property.bathroom_count }}
                    </td>
                  </tr>
                  <tr *ngIf="property.office_class_value != null">
                    <td>
                      {{ 'fields.property.office_class' | translate }}
                    </td>
                    <td>
                      {{ property.office_class_value }}
                    </td>
                  </tr>
                  <tr *ngIf="property.showcase != null">
                    <td>
                      {{ 'fields.property.showcase' | translate }}
                    </td>
                    <td>
                      {{ 'misc.' + property.showcase | translate }}
                      <span *ngIf="property.showcase_length_m">
                        ({{ property.showcase_length_m }} m)
                      </span>
                    </td>
                  </tr>
                  <tr *ngIf="property.layout_value != null">
                    <td>
                      {{ 'fields.property.layout' | translate }}
                    </td>
                    <td>
                      {{ property.layout_value }}
                    </td>
                  </tr>
                  <tr *ngIf="property.selected_orientations.length">
                    <td>
                      {{ 'fields.property.orientation' | translate }}
                    </td>
                    <td>
                      <span
                        *ngFor="
                          let orientation of property.selected_orientations;
                          let last = last
                        "
                      >
                        {{
                          'model.property.orientation.' + orientation
                            | translate
                        }}<ng-template [ngIf]="!last">, </ng-template>
                      </span>
                    </td>
                  </tr>
                  <tr *ngIf="property.kitchen_type != null">
                    <td>
                      {{ 'fields.property.kitchen_type' | translate }}
                    </td>
                    <td>
                      {{
                        'model.property.kitchen_type.' + property.kitchen_type
                          | translate
                      }}
                    </td>
                  </tr>
                </table>
              </div>

              <div
                *ngIf="has2dSection('sizes')"
                class="col-xs-12 col-sm-6 separator-right"
              >
                <h5>Suprafețe</h5>
                <table class="field-table">
                  <tr *ngIf="property.usable_size_sqm != null">
                    <td>
                      {{ 'fields.property.usable_size_sqm' | translate }}
                    </td>
                    <td>
                      {{ property.usable_size_sqm | number:'.0-2' }}
                      {{ 'misc.sqm' | translate }}
                    </td>
                  </tr>
                  <tr *ngIf="property.built_size_sqm != null">
                    <td>
                      {{ 'fields.property.built_size_sqm' | translate }}
                    </td>
                    <td>
                      {{ property.built_size_sqm | number:'.0-2' }}
                      {{ 'misc.sqm' | translate }}
                    </td>
                  </tr>
                  <tr *ngIf="property.total_size_sqm != null">
                    <td>
                      {{ 'fields.property.total_size_sqm' | translate }}
                    </td>
                    <td>
                      {{ property.total_size_sqm | number:'.0-2' }}
                      {{ 'misc.sqm' | translate }}
                    </td>
                  </tr>
                  <tr *ngIf="property.garden_size_sqm != null">
                    <td>
                      {{ 'fields.property.garden_size_sqm' | translate }}
                    </td>
                    <td>
                      {{ property.garden_size_sqm | number:'.0-2' }}
                      {{ 'misc.sqm' | translate }}
                    </td>
                  </tr>
                  <tr *ngIf="property.balcony_size_sqm != null">
                    <td>
                      {{ 'fields.property.balcony_size_sqm' | translate }}
                    </td>
                    <td>
                      {{ property.balcony_size_sqm | number:'.0-2' }}
                      {{ 'misc.sqm' | translate }}
                    </td>
                  </tr>
                  <tr *ngIf="property.terrace_size_sqm != null">
                    <td>
                      {{ 'fields.property.terrace_size_sqm' | translate }}
                    </td>
                    <td>
                      {{ property.terrace_size_sqm | number:'.0-2' }}
                      {{ 'misc.sqm' | translate }}
                    </td>
                  </tr>
                  <tr *ngIf="property.land_area_sqm != null">
                    <td>
                      {{ 'fields.property.land_area_sqm' | translate }}
                    </td>
                    <td>
                      {{ property.land_area_sqm | number:'.0-2' }}
                      {{ 'misc.sqm' | translate }}
                    </td>
                  </tr>
                  <tr *ngIf="property.front_length_m != null">
                    <td>
                      {{ 'fields.property.front_length_m' | translate }}
                    </td>
                    <td>{{ property.front_length_m | number:'.0-2' }} m</td>
                  </tr>
                  <tr *ngIf="property.space_height != null">
                    <td>
                      {{ 'fields.property.space_height' | translate }}
                    </td>
                    <td>{{ property.space_height }} m</td>
                  </tr>
                  <tr *ngIf="property.allowed_height != null">
                    <td>
                      {{ 'fields.property.allowed_height' | translate }}
                    </td>
                    <td>{{ property.allowed_height }} m</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="has2dSection('counters')" class="no-page-break">
          <div class="description-list">
            <div class="row">
              <div
                class="col-xs-12 col-sm-6"
                *ngIf="
                  hasSection([
                    'balcony_count',
                    'closed_balcony_count',
                    'terrace_count',
                    'kitchen_count',
                    'front_count',
                    'garage_count',
                    'parking_spots_count',
                    'street_front_count',
                    'land_class_value',
                    'land_type_value',
                    'pot',
                    'cut',
                    'land_info_source_value',
                    'has_buildings'
                  ])
                "
              >
                <h5>Număr</h5>
                <table class="field-table">
                  <tr *ngIf="property.balcony_count != null">
                    <td>
                      {{ 'fields.property.balcony_count' | translate }}
                    </td>
                    <td>
                      {{ property.balcony_count }}
                    </td>
                  </tr>
                  <tr *ngIf="property.closed_balcony_count != null">
                    <td>
                      {{ 'fields.property.closed_balcony_count' | translate }}
                    </td>
                    <td>
                      {{ property.closed_balcony_count }}
                    </td>
                  </tr>
                  <tr *ngIf="property.terrace_count != null">
                    <td>
                      {{ 'fields.property.terrace_count' | translate }}
                    </td>
                    <td>
                      {{ property.terrace_count }}
                    </td>
                  </tr>
                  <tr *ngIf="property.kitchen_count != null">
                    <td>
                      {{ 'fields.property.kitchen_count' | translate }}
                    </td>
                    <td>
                      {{ property.kitchen_count }}
                    </td>
                  </tr>
                  <tr *ngIf="property.front_count != null">
                    <td>
                      {{ 'fields.property.front_count' | translate }}
                    </td>
                    <td>
                      {{ property.front_count }}
                    </td>
                  </tr>
                  <tr *ngIf="property.garage_count != null">
                    <td>
                      {{ 'fields.property.garage_count' | translate }}
                    </td>
                    <td>
                      {{ property.garage_count }}
                    </td>
                  </tr>
                  <tr *ngIf="property.parking_spots_count != null">
                    <td>
                      {{ 'fields.property.parking_spots_count' | translate }}
                    </td>
                    <td>
                      {{ property.parking_spots_count }}
                    </td>
                  </tr>
                  <tr *ngIf="property.street_front_count != null">
                    <td>
                      {{ 'fields.property.street_front_count' | translate }}
                    </td>
                    <td>
                      {{ property.street_front_count }}
                    </td>
                  </tr>

                  <tr *ngIf="property.land_class_value != null">
                    <td>
                      {{ 'fields.property.land_class' | translate }}
                    </td>
                    <td>
                      {{ property.land_class_value }}
                    </td>
                  </tr>
                  <tr *ngIf="property.land_type_value != null">
                    <td>
                      {{ 'fields.property.land_type' | translate }}
                    </td>
                    <td>
                      {{ property.land_type_value }}
                    </td>
                  </tr>
                  <tr *ngIf="property.pot != null">
                    <td>
                      {{ 'fields.property.pot' | translate }}
                    </td>
                    <td>
                      {{ property.pot }}
                    </td>
                  </tr>
                  <tr *ngIf="property.cut != null">
                    <td>
                      {{ 'fields.property.cut' | translate }}
                    </td>
                    <td>
                      {{ property.cut }}
                    </td>
                  </tr>
                  <tr *ngIf="property.land_info_source_value != null">
                    <td>
                      {{ 'fields.property.land_info_source' | translate }}
                    </td>
                    <td>
                      {{ property.land_info_source_value }}
                    </td>
                  </tr>
                  <tr *ngIf="property.has_buildings != null">
                    <td>
                      {{ 'fields.property.has_buildings' | translate }}
                    </td>
                    <td>
                      {{ 'misc.' + property.has_buildings | translate }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="
            has2dSection('facilities') &&
            hasSection([
              'utility_values',
              'facility_values',
              'finish_values',
              'roof_material_values',
              'service_values',
              'zone_detail_values',
              'destination_values',
              'other_characteristic_values'
            ])
          "
          class="no-page-break"
        >
          <h5>Facilități</h5>
          <div class="description-list">
            <div class="facilities flex flex-col md:flex-row gap-2.5">
              <div
                *ngIf="
                  property.utility_values && property.utility_values.length
                "
                class="flex-1"
              >
                <strong>
                  {{ 'fields.property.utilities' | translate }}
                </strong>

                <ul>
                  <li *ngFor="let value of property.utility_values">
                    {{ value }}
                  </li>
                </ul>
              </div>
              <div
                *ngIf="
                  property.facility_values && property.facility_values.length
                "
                class="flex-1"
              >
                <strong>
                  {{ 'fields.property.facilities' | translate }}
                </strong>

                <ul>
                  <li *ngFor="let value of property.facility_values">
                    {{ value }}
                  </li>
                </ul>
              </div>
              <div
                *ngIf="property.finish_values && property.finish_values.length"
                class="flex-1"
              >
                <strong>
                  {{ 'fields.property.finishes' | translate }}
                </strong>

                <ul>
                  <li *ngFor="let value of property.finish_values">
                    {{ value }}
                  </li>
                </ul>
              </div>
              <div
                *ngIf="
                  property.roof_material_values &&
                  property.roof_material_values.length
                "
                class="flex-1"
              >
                <strong>
                  {{ 'fields.property.roof_materials' | translate }}
                </strong>

                <ul>
                  <li *ngFor="let value of property.roof_material_values">
                    {{ value }}
                  </li>
                </ul>
              </div>
              <div
                *ngIf="
                  property.service_values && property.service_values.length
                "
                class="flex-1"
              >
                <strong>
                  {{ 'fields.property.services' | translate }}
                </strong>

                <ul>
                  <li *ngFor="let value of property.service_values">
                    {{ value }}
                  </li>
                </ul>
              </div>
              <div
                *ngIf="
                  property.zone_detail_values &&
                  property.zone_detail_values.length
                "
                class="flex-1"
              >
                <strong>
                  {{ 'fields.property.zone_details' | translate }}
                </strong>

                <ul>
                  <li *ngFor="let value of property.zone_detail_values">
                    {{ value }}
                  </li>
                </ul>
              </div>
              <div
                *ngIf="
                  property.destination_values &&
                  property.destination_values.length
                "
                class="flex-1"
              >
                <strong>
                  {{ 'fields.property.destinations' | translate }}
                </strong>

                <ul>
                  <li *ngFor="let value of property.destination_values">
                    {{ value }}
                  </li>
                </ul>
              </div>
              <div
                *ngIf="
                  property.other_characteristic_values &&
                  property.other_characteristic_values.length
                "
                class="flex-1"
              >
                <strong>
                  {{ 'fields.property.other_characteristics' | translate }}
                </strong>

                <ul>
                  <li
                    *ngFor="let value of property.other_characteristic_values"
                  >
                    {{ value }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="has2dSection('files') && property.documents.length"
          class="files no-page-break"
        >
          <h5>Fisiere locuință</h5>
          <a
            *ngFor="let file of property.documents"
            [href]="file.url"
            target="_blank"
            class="flex jc-between ai-center"
          >
            <span>{{ file.name }}</span>
            <label>Descarcă</label>
          </a>
        </div>
        <p
          *ngIf="has2dSection('description') && property.description"
          class="description no-page-break"
          [innerHTML]="property.description"
        ></p>
      </div>

      <div
        class="virtual-tour"
        *ngIf="has2dSection('virtual_tour') && property.virtual_tour_url"
      >
        <h2>Tur virtual</h2>
        <iframe
          [src]="virtualTourIframeSrc"
          frameborder="0"
          allowfullscreen
          allow="xr-spatial-tracking"
        ></iframe>
      </div>

      <div class="embedHtml dn--print" #embedHtml></div>

      <div *ngIf="withLike" class="like dn--print">
        <h3>Îți place? Acordă un status ofertei</h3>
        <br />
        <div class="flex gap-5 justify-center mb-2.5">
          <mat-icon
            *ngIf="likeDislike != 'like'"
            (click)="feedbackClick('like')"
            fontSet="material-icons-outlined"
          >
            thumb_up
          </mat-icon>
          <mat-icon
            *ngIf="likeDislike == 'like'"
            (click)="feedbackClick('like')"
            class="selected"
          >
            thumb_up
          </mat-icon>
          <mat-icon
            *ngIf="likeDislike != 'dislike'"
            (click)="feedbackClick('dislike')"
            fontSet="material-icons-outlined"
          >
            thumb_down
          </mat-icon>
          <mat-icon
            *ngIf="likeDislike == 'dislike'"
            (click)="feedbackClick('dislike')"
            class="selected"
          >
            thumb_down
          </mat-icon>
        </div>
      </div>
    </div>

    <aside *ngIf="agent">
      <div class="contact-agent flex flex-col justify-center items-center">
        <h2>Contact agent</h2>
        <div>
          <div
            class="flex flex-row items-center gap-2.5"
            *ngIf="agent.full_name"
          >
            <mat-icon>person</mat-icon>
            <div>
              {{ agent.full_name }}
            </div>
          </div>
          <a
            *ngIf="agent.phone"
            class="flex flex-row items-center gap-2.5"
            [href]="'tel:' + agent.phone"
          >
            <mat-icon>phone</mat-icon>
            <div>
              {{ agent.phone | beautify }}
            </div>
          </a>
          <a
            *ngIf="agent.email"
            class="flex flex-row items-center gap-2.5"
            [href]="'mailto:' + agent.email"
            target="_blank"
          >
            <mat-icon>email</mat-icon>
            <div>
              {{ agent.email }}
            </div>
          </a>
        </div>
      </div>
    </aside>
  </div>
</section>
