<div class="ap-wrap">
  <img
    #image
    [src]="imageUrl"
    class="ap-img"
    loading="eager"
  />

  <svg
    #map
    class="overlay"
  >
    <g
      *ngFor="let area of processedAreas; let index = index; trackBy: listTrackingService.disableUpdate"
      class="poly-wrapper"
      [class.not-available]="area.notAvailable"
      (mouseenter)="hoverIndex = index"
      (mouseleave)="hoverIndex = null"
    >
      <polygon
        [attr.points]="area.coords.join(' ')"
        fill="transparent"
        [class.has-link]="area.link?.length"
        [routerLink]="area.link"
      />

      <g *ngIf="area.textBox">
        <rect
          class="map-text-bg"
          [attr.x]="getBB(textBox).x - 2"
          [attr.y]="getBB(textBox).y - 1"
          [attr.width]="getBB(textBox).width + 4"
          [attr.height]="getBB(textBox).height + 2"
        ></rect>

        <text
          #textBox
          class="map-text"
          [attr.x]="area.textBox.x"
          [attr.y]="area.textBox.y + 22"
        >
          {{ area.textBox.text }}
        </text>
      </g>
    </g>


    <ng-container *ngFor="let area of processedAreas; let index = index; trackBy: listTrackingService.disableUpdate">
      <g
        *ngIf="area.tooltipBox"
        class="tooltip-wrapper"
        [class.visible]="hoverIndex === index"
      >
        <rect
          class="tooltip-text-bg"
          [attr.x]="getBB(text).x - 6"
          [attr.y]="getBB(text).y - 6"
          [attr.width]="getBB(text).width + 12"
          [attr.height]="getBB(text).height + 12"
        ></rect>

        <text
          #text
          class="tooltip-text"
          [attr.x]="area.tooltipBox.x"
          [attr.y]="area.tooltipBox.y"
        >
          {{ area.tooltipBox.text }}
        </text>
      </g>
    </ng-container>
  </svg>
</div>
