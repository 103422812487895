import {
  Component,
  Input,
} from '@angular/core';
import { Agency, AgencyService, Project } from 'api';
import { ListTrackingService } from '../utils/list-tracking.service';

@Component({
  selector: 'app-map-plan-project-base',
  templateUrl: './map-plan-project-base.component.html',
})
export class MapPlanProjectBaseComponent {
  @Input() agency: Agency;
  @Input() projects: Project[];

  get areas() {
    return this.projects.map((project: Project) => ({
      coords: project.area,
      link: this.getHref(project.id),
      tooltip: project.name,
      notAvailable: project.sale_status === 'sold_out',
    }));
  }

  constructor(
    private agencyService: AgencyService,
    public listTrackingService: ListTrackingService,
  ) { }

  getHref(projectId: number): string {
    return '/' + this.agencyService.agency.uuid + '/2d/building/' + projectId;
  }
}
