<header
  *ngIf="agencyService.agency"
  class="custom-container no-page-break flex flex-row justify-between items-center"
>
  <a
    *ngIf="
      agencyService.agency.website_url &&
      agencyService.agency.logo &&
      agencyService.agency.logo.url
    "
    class="agency-url"
    [href]="agencyService.agency.website_url"
    target="_blank"
  >
    <img
      class="logo"
      [src]="agencyService.agency.logo.url"
      loading="eager"
    />
  </a>
  <img
    *ngIf="
      !agencyService.agency.website_url &&
      agencyService.agency.logo &&
      agencyService.agency.logo.url
    "
    class="logo"
    [src]="agencyService.agency.logo.url"
    loading="eager"
  />
  <h1>
    {{ agencyService.agency.name }}
    <a
      *ngIf="agencyService.agency.website_url"
      class="agency-url"
      [href]="agencyService.agency.website_url"
      target="_blank"
    >
      {{ agencyUrl() }}
    </a>
  </h1>
</header>
