<button
  type="button"
  *ngIf="hasBack()"
  class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded mb-5 ml-5 w-48 text-center"
  (click)="goBack()"
>
  Înapoi la proiect
</button>

<app-map-plan-building-base
  [project]="project"
  [buildingFloors]="buildingFloors"
></app-map-plan-building-base>
