import { Image, Property } from './index';

export class BuildingFloor {
  id: number;
  floor_value: string;
  area: string[];
  map_plan: Image;
  properties: Property[] = [];
  sale_status: 'sold_out' | 'available';
}
