import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, TransferState } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';


import { HouseDetailsComponent } from './houses/house-details.component';
import { OfferDetailsComponent } from './offers/offer-details.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './shared/header/header.component';

import { MatIconModule } from '@angular/material/icon';

// Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { exportTranslateStaticLoader } from 'libs/common/src/i18n/translate-browser-loader.service';

import { MatButtonModule } from '@angular/material/button';
import { CommonThemeModule } from 'common';
import { MapPlanBuildingsComponent } from './map_plan/map-plan-buildings.component';
import { MapPlanDetailsComponent } from './map_plan/map-plan-details.component';
import { MapPlanFloorsComponent } from './map_plan/map-plan-floors.component';
import { MapPlanComponent } from './map_plan/map-plan.component';
import { MapPlanRouterComponent } from './map_plan/map-plan-router.component';

import localeRoExtra from '@angular/common/locales/extra/ro';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
registerLocaleData(localeRo, 'ro-RO', localeRoExtra);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HouseDetailsComponent,
    OfferDetailsComponent,
    MapPlanBuildingsComponent,
    MapPlanDetailsComponent,
    MapPlanFloorsComponent,
    MapPlanComponent,
    MapPlanRouterComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState],
      },
    }),
    CommonThemeModule,
    MatIconModule,
    HammerModule,
    MatButtonModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ro-RO'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
