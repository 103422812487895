<div>
  <app-map-plan
    [imageUrl]="buildingFloor.map_plan.url"
    [areas]="areas"
  ></app-map-plan>

  <div class="text-center font-bold text-gray-800 mt-3 md:hidden">
    Mărește planul de etaj cu 2 degete
  </div>
</div>

<div class="block md:hidden mt-2 mb-5 px-5">
  <h1 class="text-center mb-5 text-2xl font-bold">
    Locuințe {{ buildingFloor.floor_value }}:
  </h1>

  <a
    *ngFor="
      let property of buildingFloor.properties;
      trackBy: listTrackingService.disableUpdate
    "
    [routerLink]="getHref(property)"
    class="w-full block border-b border-gray-400 pb-5 mb-5 last:border-b-0 last:pb-0 last:mb-0"
  >
    <app-property-card [property]="property"></app-property-card>
  </a>
</div>
