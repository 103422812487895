import {
  Component,
  OnInit,
  Injector,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Lightbox,
  LightboxConfig,
  LightboxEvent,
  LIGHTBOX_EVENT,
  IAlbum,
} from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { BaseComponent } from './base-component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Agent, Image, Money, Property, PropertyService } from 'api';

@Component({
  selector: 'app-house-details-base',
  template: '',
})
export class HouseDetailsBaseComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('embedHtml', { static: true }) embedHtml: ElementRef;
  @Input() property: Property;
  @Input() promoPrice: Money;
  @Input() noMap: boolean = false;

  @Input() agent: Agent;
  @Input() withLike: boolean = false;
  @Input() likeDislike: string = null;
  @Output() feedback: EventEmitter<string> = new EventEmitter<string>();

  zoom: number = 12;
  characteristics: string;
  private _albums: IAlbum[];
  private _subscription: Subscription;

  _iframeSrc: SafeResourceUrl;
  get virtualTourIframeSrc(): SafeResourceUrl {
    if (this._iframeSrc) return this._iframeSrc;
    if (!this.property || !this.property.virtual_tour_url) {
      this._iframeSrc = null;
      return null;
    }

    this._iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.property.virtual_tour_url
    );
    return this._iframeSrc;
  }

  constructor(
    injector: Injector,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private propertyService: PropertyService,
    private _lightbox: Lightbox,
    private _lightboxEvent: LightboxEvent,
    private _lighboxConfig: LightboxConfig
  ) {
    super(injector);
  }

  ngAfterViewInit(): void {
    if (this.embedHtml) {
      this.embedHtml.nativeElement.querySelectorAll('script')

      Array.from(this.embedHtml.nativeElement.querySelectorAll('script')).forEach((script: HTMLScriptElement) => {
        const s = document.createElement('script')
        if (script.innerText) s.innerText = script.innerText;
        if (script.src) s.src = script.src;
        document.body.appendChild(s);
      });
    }
  }

  ngOnInit(): void {
    if (this.route.snapshot.data['property']) {
      this.property = this.route.snapshot.data['property'];
      this.characteristics = this.propertyService.generateCharacteristicsString(
        this.property
      );

      this.initLightbox(this.property.images);

      if (this.property.embed_html && this.embedHtml?.nativeElement) {
        this.embedHtml.nativeElement.innerHTML = this.property.embed_html;
      }
    }

    const agentQuery = this.route.snapshot.queryParams['a'];

    if (agentQuery) {
      const agentHash = JSON.parse(
        atob(agentQuery.replace('-', '+').replace('/', '_'))
      );
      const agentEmail = agentHash['agent_email'];
      const agentPhone = agentHash['agent_phone'];
      const agentFullName = agentHash['agent_full_name'];

      if (agentHash['no_map']) this.noMap = agentHash['no_map'];

      if (agentEmail || agentPhone || agentFullName) {
        this.agent = new Agent();
        this.agent.email = agentEmail;
        this.agent.phone = agentPhone;
        this.agent.full_name = agentFullName;
      }
    }
  }

  feedbackClick(likeDislike: string): void {
    this.feedback.emit(likeDislike);
  }

  open(index: number): void {
    this._lighboxConfig.positionFromTop =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0) +
      20;
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event) =>
      this._onReceivedEvent(event)
    );
    this._lightbox.open(this._albums, index, {
      wrapAround: true,
      showImageNumberLabel: true,
    });
  }

  private _onReceivedEvent(event: any): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this._subscription.unsubscribe();
    }
  }

  initLightbox(images: Image[]): void {
    this._albums = [];
    for (const image of images) {
      const album = {
        src: image.url,
        downloadUrl: image.url,
        caption: image.description,
        thumb: image.width_560_url,
      };

      this._albums.push(album);
    }

    this._lighboxConfig.fadeDuration = 1;
  }

  hasSection(fields: string[]): boolean {
    for (let i = 0; i <= fields.length; i++) {
      if (
        this.property[fields[i]] != null &&
        this.property[fields[i]] !== undefined
      ) {
        if (this.property[fields[i]].length !== undefined) {
          if (this.property[fields[i]].length) return true;
        } else {
          return true;
        }
      }
    }
    return false;
  }
}
