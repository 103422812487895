<button
  type="button"
  class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded mb-5 ml-5 w-48 text-center"
  (click)="goBack()"
>
  Înapoi la clădire
</button>

<app-map-plan-building-floor-base
  [buildingFloor]="buildingFloor"
></app-map-plan-building-floor-base>
