import { Component, OnInit, Injector, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgencyService, BuildingFloor, Project } from 'api';
import { BaseComponent } from 'common';

@Component({
  selector: 'app-map-plan-buildings',
  templateUrl: './map-plan-buildings.component.html',
})
export class MapPlanBuildingsComponent extends BaseComponent implements OnInit {
  project: Project;
  buildingFloors: BuildingFloor[];

  constructor(
    injector: Injector,
    public agencyService: AgencyService,
    public route: ActivatedRoute,
    @Inject('WINDOW') public window: any
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.route.data.forEach((data: { project: Project }) => {
      this.project = data.project;
      this.setTitle(this.agencyService.agency.name, this.project.name);
    });
    this.route.data.forEach(
      (data: { building_floors: BuildingFloor[] }) =>
        (this.buildingFloors = data.building_floors)
    );
  }
}
