import { Image } from './index';
import { Property } from './index';

export class Project {
  id: number;
  name: string;
  description: string;
  status: string;
  sale_status: 'sold_out' | 'available';
  website_link: string;
  zone_name: string;
  avatar: Image | string;
  latitude: number;
  longitude: number;
  website_latitude: number;
  website_longitude: number;
  map_plan: Image;
  area: string[];
  images: Image[] = [];
  related_projects: Project[] = [];
  properties: Property[] = [];
  available_properties_count: number;
}
