import {
  Component,
  Input,
} from '@angular/core';
import { AgencyService, BuildingFloor, Project } from 'api';
import { ListTrackingService } from '../utils/list-tracking.service';

@Component({
  selector: 'app-map-plan-building-base',
  templateUrl: './map-plan-building-base.component.html',
})
export class MapPlanBuildingBaseComponent {
  @Input() project: Project;
  @Input() buildingFloors: BuildingFloor[] = [];

  get areas() {
    return this.buildingFloors.map((buildingFloor: BuildingFloor) => ({
      coords: buildingFloor.area,
      link: this.getHref(buildingFloor.id),
      tooltip: buildingFloor.floor_value,
      notAvailable: buildingFloor.sale_status === 'sold_out',
    }));
  }

  constructor(
    private agencyService: AgencyService,
    public listTrackingService: ListTrackingService,
  ) { }

  getHref(buildingFloorId: number): string {
    return (
      '/' +
      this.agencyService.agency.uuid +
      '/2d/building/' +
      this.project.id +
      '/' +
      buildingFloorId
    );
  }
}
