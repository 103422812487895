import { Component, Input } from '@angular/core';
import { HouseDetailsBaseComponent } from 'common';

@Component({
  selector: 'app-house-details',
  templateUrl: './house-details.component.html',
  styleUrls: ['./house-details.component.scss'],
})
export class HouseDetailsComponent extends HouseDetailsBaseComponent {
  @Input() exposed2dSections: string[];

  has2dSection(section: string): boolean {
    if (!this.exposed2dSections) return true;

    return this.exposed2dSections.includes(section);
  }
}
