import {
  Component,
  Input,
} from '@angular/core';
import { AgencyService, BuildingFloor, Property } from 'api';
import { ListTrackingService } from '../utils/list-tracking.service';

@Component({
  selector: 'app-map-plan-building-floor-base',
  templateUrl: './map-plan-building-floor-base.component.html',
  styleUrls: ['./map-plan.component.scss'],
})
export class MapPlanBuildingFloorBaseComponent {
  @Input() buildingFloor: BuildingFloor;
  @Input() propertyToolTipText: (property: Property) => string;

  get areas() {
    return this.buildingFloor?.properties.map((property) => ({
      coords: property.area,
      link: this.getHref(property),
      tooltip: this.propertyToolTip(property),
      notAvailable: property.availability !== 'available',
    }));
  }

  constructor(
    private agencyService: AgencyService,
    public listTrackingService: ListTrackingService
  ) { }

  private propertyToolTip(property: Property): string {
    if (this.propertyToolTipText) return this.propertyToolTipText(property);

    let copy = property.title + ' [nr: ' + property.number + ']';

    return copy;
  }

  private canSeeAvailability(): boolean {
    return (
      !this.agencyService.agency.exposed_2d_sections ||
      this.agencyService.agency.exposed_2d_sections?.includes('availability')
    );
  }

  getHref(property: Property): string {
    if (this.canSeeAvailability() && property.availability !== 'available') {
      return null;
    }

    return '/' + this.agencyService.agency.uuid + '/2d/' + property.friendly_id;
  }
}
