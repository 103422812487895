import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ListTrackingService } from '../utils/list-tracking.service';
import { Router } from '@angular/router';
import { AgencyService } from 'api';

export interface MapPlanArea {
  coords: string[];
  link?: string;
  tooltip: string;
  notAvailable?: boolean;
}

interface MapPlanTextArea {
  text: string;
  x: number;
  y: number;
}

interface MapPlanProcessedArea {
  coords: string[];
  notAvailable?: boolean;
  link?: string;
  textBox?: MapPlanTextArea;
  tooltipBox?: MapPlanTextArea;
}

@Component({
  selector: 'app-map-plan',
  templateUrl: './map-plan.component.html',
  styleUrls: ['./map-plan.component.scss'],
})
export class MapPlanComponent implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true }) imageUrl: string;
  @Input({ required: true }) areas: MapPlanArea[];

  @ViewChild('image', { static: true }) private image: ElementRef<HTMLImageElement>;
  @ViewChild('map', { static: true }) private map: ElementRef<SVGElement>;

  processedAreas: MapPlanProcessedArea[] = [];

  private observer: ResizeObserver;

  hoverIndex: number = null;

  constructor(
    private agencyService: AgencyService,
    public listTrackingService: ListTrackingService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.observer = new ResizeObserver(() => {
      this.setMapSize();
    });

    this.observer.observe(this.image.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('areas' in changes) {
      this.processAreas();
    }
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  highlight(event: Event) {
    (event.target as SVGElement).classList.add('highlight');
  }

  unhighlight(event: Event) {
    (event.target as SVGElement).classList.remove('highlight');
  }

  onRegionClick(area: MapPlanArea) {
    if (area.link) {
      this.router.navigate([area.link]);
    }
  }

  getBB(text: any) {
    return text.getBBox();
  }

  private setMapSize() {
    const img = this.image.nativeElement;
    const svg = this.map.nativeElement;

    // Set viewBox to match original image size
    svg.setAttribute('viewBox', `0 0 ${img.naturalWidth} ${img.naturalHeight}`);
    svg.setAttribute('width', `${img.clientWidth}`);
    svg.setAttribute('height', `${img.clientHeight}`);
  }

  private processAreas() {
    this.processedAreas = this.areas.map((area) => {
      const processedArea: MapPlanProcessedArea = {
        coords: area.coords,
        notAvailable: area.notAvailable,
        link: area.link,
      };

      if (this.canSeeAvailability()) {
        const [x, y] = this.getMiddleCenter(area.coords);
        processedArea.textBox = { text: area.notAvailable ? 'Indisponibil' : 'Disponibil', x, y }
      }

      if (area.tooltip) {
        const [x, y] = this.getTopCenter(area.coords);
        processedArea.tooltipBox = { text: area.tooltip, x, y }
      }

      return processedArea;
    });
  }

  private getTopCenter(coords: string[]): [number, number] {
    const points = coords.map((point) => point.split(',').map((p) => parseInt(p)));
    let minY = Infinity, maxY = -Infinity, sumX = 0;

    points.forEach(([x, y]) => {
      if (y < minY) minY = y;
      if (y > maxY) maxY = y;
      sumX += x;
    });

    const centerX = sumX / points.length;
    return [centerX, minY];
  }

  private getMiddleCenter(coords: string[]): [number, number] {
    const points = coords.map((point) => point.split(',').map((p) => parseFloat(p)));
    let minY = Infinity, maxY = -Infinity, sumX = 0;

    points.forEach(([x, y]) => {
      if (y < minY) minY = y;
      if (y > maxY) maxY = y;
      sumX += x;
    });

    const centerX = sumX / points.length;
    const centerY = (minY + maxY) / 2;
    return [centerX, centerY];
  }

  private canSeeAvailability(): boolean {
    return (
      !this.agencyService.agency.exposed_2d_sections ||
      this.agencyService.agency.exposed_2d_sections?.includes('availability')
    );
  }

  /*
  private getCenter(coords: string[]): [number, number] {
    const points = coords.map((point) => point.split(',').map((p) => parseFloat(p)));
    let x = 0, y = 0, area = 0, factor = 0;

    for (let i = 0; i < points.length; i++) {
      const [x1, y1] = points[i];
      const [x2, y2] = points[(i + 1) % points.length];
      factor = (x1 * y2 - x2 * y1);
      area += factor;
      x += (x1 + x2) * factor;
      y += (y1 + y2) * factor;
    }

    area = Math.abs(area) / 2;  // Ensure positive area
    x /= (6 * area);
    y /= (6 * area);

    return [x, y];
  }
  */
}
