import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../../../../../libs/common/src/index';
import { ActivatedRoute } from '@angular/router';
import { Agency, AgencyService, Offer, OfferProperty, OfferPropertyService, Project } from 'api';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsComponent extends BaseComponent implements OnInit {
  offer: Offer;
  agencyOffer: Agency;
  projects: Project[] = [];

  constructor(
    injector: Injector,
    public agencyService: AgencyService,
    private offerPropertyService: OfferPropertyService,
    private route: ActivatedRoute
  ) {
    super(injector);
  }

  sendFeedback(offerProperty: OfferProperty, status: string): void {
    offerProperty.status = status;
    this.offerPropertyService
      .sendOfferPropertyFeedback(
        this.route.snapshot.params['agency_uuid'],
        this.route.snapshot.params['uuid'],
        offerProperty
      )
      .then((response) => (offerProperty.status = response.status));
  }

  ngOnInit(): void {
    this.offer = this.route.snapshot.data['offer'];
    this.agencyOffer = this.route.snapshot.data['agency_offer'];

    if (this.route.snapshot.queryParams['snapshot']) {
      this.agencyOffer = this.offer.agency_offer_snapshot_data;
      this.offer = this.offer.offer_snapshot_data;
    }

    const projects = this.offer.offers_properties.map((offerProperty) => offerProperty.property.project);
    this.projects = projects.filter(
      (project, index) => project && projects.findIndex((p) => p && p.id === project.id) === index
    );
  }

  getPropertiesOfProjectId(projectId: number): OfferProperty[] {
    return this.offer.offers_properties.filter(
      (offerProperty) => offerProperty.property.project.id === projectId
    );
  }
}
