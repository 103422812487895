import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BuildingFloor } from 'api';
import { BaseComponent } from 'common';

@Component({
  selector: 'app-map-plan-floors',
  templateUrl: './map-plan-floors.component.html',
})
export class MapPlanFloorsComponent extends BaseComponent implements OnInit {
  buildingFloor: BuildingFloor;

  constructor(injector: Injector, private route: ActivatedRoute) {
    super(injector);
  }

  ngOnInit(): void {
    this.route.data.forEach(
      (data: { building_floor: BuildingFloor }) =>
        (this.buildingFloor = data.building_floor)
    );
  }
}
