<app-map-plan
  [imageUrl]="agency.map_plan.url"
  [areas]="areas"
></app-map-plan>

<div class="flex md:hidden flex-col items-center my-10">
  <h1 class="text-center mb-5 text-2xl font-bold">Selectează clădirea:</h1>

  <a
    *ngFor="
      let project of projects;
      trackBy: listTrackingService.disableUpdate;
      block
    "
    [routerLink]="'/' + getHref(project.id)"
    class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded w-48 mb-5 text-center"
  >
    {{ project.name }}
  </a>
</div>
