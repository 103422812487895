import { NgModule, TransferState } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LightboxModule } from 'ngx-lightbox';

// Components
import { ImagesCarouselBaseComponent } from './images-carousel-base/images-carousel-base.component';
import { HouseDetailsBaseComponent } from './house-details-base.component';
import { GoogleMapIframeComponent } from './gmaps/google-map/google-map-iframe.component';
import { OpenStreetMapComponent } from './osm/open-street-map.component';

import { LocaleService } from './seo/locale.service';

// Tooltips

// Translate
import { TranslateModule } from '@ngx-translate/core';
import { TranslateLoader } from '@ngx-translate/core';
import { exportTranslateStaticLoader } from './i18n/translate-browser-loader.service';

// Pipes
import { BeautifyPhonePipe } from './pipes/beautify-phone.pipe';

/* tslint:disable:only-arrow-functions */
export function getWindow(): any {
  return typeof window !== 'undefined' ? window : null;
}

export function getScreen(): any {
  return typeof screen !== 'undefined' ? screen : null;
}
/* tslint:enable */

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { ListTrackingService } from './utils/list-tracking.service';
import { PropertyCardComponent } from './property-card/property-card.component';
import { MatIconModule } from '@angular/material/icon';
import { MapPlanBuildingBaseComponent } from './map_plan/map-plan-building-base.component';
import { MapPlanBuildingFloorBaseComponent } from './map_plan/map-plan-building-floor-base.component';
import { MapPlanProjectBaseComponent } from './map_plan/map-plan-project-base.component';
import { MapPlanProjectMobileBaseComponent } from './map_plan/map-plan-project-mobile-base.component';
import { TouchGuideComponent } from './map_plan/touch-guide.component';
import { FooterComponent } from './footer/footer.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ContactFormComponent } from './contact-form-base/contact-form.component';
import { MapPlanComponent } from './map_plan/map-plan.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FormsModule,
    LightboxModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState],
      },
    }),
  ],
  providers: [
    LocaleService,
    ListTrackingService,
    { provide: 'WINDOW', useFactory: getWindow },
    { provide: 'SCREEN', useFactory: getScreen },
  ],
  declarations: [
    BeautifyPhonePipe,
    ImagesCarouselBaseComponent,
    HouseDetailsBaseComponent,
    GoogleMapIframeComponent,
    OpenStreetMapComponent,
    MapPlanComponent,
    PropertyCardComponent,
    MapPlanBuildingBaseComponent,
    MapPlanBuildingFloorBaseComponent,
    MapPlanProjectBaseComponent,
    MapPlanProjectMobileBaseComponent,
    TouchGuideComponent,
    FooterComponent,
    FormErrorComponent,
    ContactFormComponent,
  ],
  exports: [
    BeautifyPhonePipe,
    FooterComponent,
    ImagesCarouselBaseComponent,
    HouseDetailsBaseComponent,
    GoogleMapIframeComponent,
    OpenStreetMapComponent,
    PropertyCardComponent,
    MapPlanComponent,
    MapPlanBuildingBaseComponent,
    MapPlanProjectBaseComponent,
    MapPlanProjectMobileBaseComponent,
    MapPlanBuildingFloorBaseComponent,
    TouchGuideComponent,
    ContactFormComponent,
  ],
})
export class CommonThemeModule {}
