import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { ApiCallService } from './api-call.service';
import { Offer } from '../models/index';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class OfferService extends BaseApiService implements Resolve<Offer> {
  constructor(
    private apiCallService: ApiCallService,
  ) {
    super();
  }

  getOffer(agencyUuid: string, uuid: string): Promise<Offer> {
    this.apiCallService.agencyUuid = agencyUuid;
    return this.apiCallService.get('/offers/' + uuid);
  }

  requestDetails(agencyUuid: string, uuid: string): Promise<Offer> {
    this.apiCallService.agencyUuid = agencyUuid;
    return this.apiCallService.post(`/offers/${uuid}/request_details`, null);
  }

  logLinkOpened(agencyUuid: string, uuid: string): Promise<Offer> {
    this.apiCallService.agencyUuid = agencyUuid;
    return this.apiCallService.get(`/offers/${uuid}/link_opened`);
  }

  resolve(route: ActivatedRouteSnapshot): Promise<Offer> {
    if (route.queryParams['utm_source'] === 'vaunt') {
      this.logLinkOpened(route.params['agency_uuid'], route.params['uuid'])
    }

    const promise = this.getOffer(
      route.params['agency_uuid'],
      route.params['uuid']
    ).catch((error) => {
      throw error;
    });

    return promise;
  }
}
