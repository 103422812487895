import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HouseDetailsComponent } from './houses/house-details.component';
import { OfferDetailsComponent } from './offers/offer-details.component';
import { MapPlanComponent } from './map_plan/map-plan.component';
import { MapPlanRouterComponent } from './map_plan/map-plan-router.component';
import { MapPlanBuildingsComponent } from './map_plan/map-plan-buildings.component';
import { MapPlanFloorsComponent } from './map_plan/map-plan-floors.component';
import { MapPlanDetailsComponent } from './map_plan/map-plan-details.component';
import { AgencyService, PropertyService, ProjectService, BuildingFloorService, AgencyOfferService, OfferService } from 'api';

const routes: Routes = [
  {
    path: ':agency_uuid/locuinte/:friendly_id',
    component: HouseDetailsComponent,
    resolve: {
      agency: AgencyService,
      property: PropertyService,
    },
  },
  {
    path: ':agency_uuid/2d/:friendly_id',
    component: MapPlanDetailsComponent,
    resolve: {
      agency: AgencyService,
      property: PropertyService,
    },
  },
  {
    path: ':agency_uuid/2d',
    component: MapPlanComponent,
    resolve: {
      agency: AgencyService,
      projects: ProjectService,
    },
  },
  {
    path: ':agency_uuid/2d/building/:project_id',
    component: MapPlanRouterComponent,
    resolve: {
      agency: AgencyService,
    },
    children: [
      {
        path: '',
        component: MapPlanBuildingsComponent,
        resolve: {
          project: ProjectService,
          building_floors: BuildingFloorService,
        },
      },
      {
        path: ':building_floor_id',
        component: MapPlanFloorsComponent,
        resolve: {
          building_floor: BuildingFloorService,
        },
      },
    ],
  },
  {
    path: ':agency_uuid/oferte/:uuid',
    component: OfferDetailsComponent,
    resolve: {
      agency: AgencyService,
      agency_offer: AgencyOfferService,
      offer: OfferService,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
