import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgencyService, Project } from 'api';
import { BaseComponent } from 'common';

@Component({
  selector: 'app-map-plan',
  templateUrl: './map-plan.component.html',
})
export class MapPlanComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  projects: Project[] = [];

  constructor(
    injector: Injector,
    private route: ActivatedRoute,
    public agencyService: AgencyService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.route.data.forEach((data: { projects: Project[] }) => {
      this.projects = data.projects.filter((project) => project.map_plan.url);
    });
  }
}
