import { Component, OnInit, Injector } from '@angular/core';
import { Agency, AgencyService } from 'api';
import { BaseComponent } from 'common';

@Component({
  selector: 'app-map-plan-details',
  templateUrl: './map-plan-details.component.html',
})
export class MapPlanDetailsComponent extends BaseComponent implements OnInit {
  public agency: Agency;

  constructor(injector: Injector, public agencyService: AgencyService) {
    super(injector);
  }

  ngOnInit(): void {
    this.agency = this.agencyService.agency;
  }
}
